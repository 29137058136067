import React from "react"
import Helmet from "react-helmet"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ManagementTeam from "../components/managementTeam"

const ContactUs = () => {
  const [validated, setValidated] = React.useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Row>
        <Col sm={12}>
          <h1>Contact Us</h1>
        </Col>
        <Col md={7}>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="w-md-75 mx-auto"
            method={"post"}
            action={"/send.php"}
          >
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control required type="text" name="form-firstName" />
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control required type="text" name="form-lastName" />
            </Form.Group>

            <Form.Group controlId="formCompany">
              <Form.Label>Company</Form.Label>
              <Form.Control required type="text" name="form-company" />
            </Form.Group>

            <Form.Group controlId="formAddress1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control required type="text" name="form-address1" />
            </Form.Group>

            <Form.Group controlId="formAddress2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="text" name="form-address2" />
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control required type="text" name="form-city" />
            </Form.Group>

            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <Form.Control required type="text" name="form-state" />
            </Form.Group>

            <Form.Group controlId="formZip">
              <Form.Label>Zip</Form.Label>
              <Form.Control required type="text" name="form-zip" />
            </Form.Group>

            <Form.Group controlId="formDaytimePhone">
              <Form.Label>Daytime Phone</Form.Label>
              <Form.Control required type="tel" name="form-daytimePhone" />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" name="form-email" />
            </Form.Group>

            <Form.Group controlId="formMoreInfo">
              <Form.Label>I would like more information about</Form.Label>
              <Form.Control type="text" name="form-moreInfo" />
            </Form.Group>

            <Form.Label>Contact me by</Form.Label>
            <Form.Check
              type="checkbox"
              label="Phone"
              name="form-contactMethod-Phone"
            />
            <Form.Check
              type="checkbox"
              label="Email"
              name="form-contactMethod-Email"
            />

            <Form.Control
              type="hidden"
              id="g-recaptcha"
              name="g_recaptcha"
              required
            ></Form.Control>

            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
          <Helmet>
            <script src="/js/contact_recaptcha.js"></script>
          </Helmet>
        </Col>
        <Col md={5}>
          <iframe
            title="googleMap"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJQSZ90uNMyocRRJXVNldpYKs&key=AIzaSyDq2LEd3exWofep7Y74g26cM69T5z_EKGw"
            allowfullscreen
          ></iframe>
          <ManagementTeam />
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactUs
