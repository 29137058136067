import React from "react"

const ManagementTeam = () => [
  <>
    <h3>Our Management Team</h3>
    <p>
      Steven Spradlin - President
      <br />
      Andy Pipkins - VP of Operations and Lumber Purchasing
      <br />
      Matt Howard - VP of Logistics and Engineered Products Purchasing
      <br />
      Justin Williams and Wes Sadler Sales
      <br />
      Coleman Klineline, Jeff Earnhart &amp; Dave Earnhart – Design Team
      <br />
      Jenny Cavitt and Spencer Bradley - Accounting Team
      <br />
      Roberto Infante - Quality Control
    </p>
  </>,
]

export default ManagementTeam
